import { Link, Carousel, Button, InputGroup, Accordion, FormControl, Row, Col, ProgressBar, Form, Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

AOS.init();
export default function DragProject() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Button className='modal-btn' variant="primary" onClick={handleShow}>
                Drag Project
            </Button>

            <Modal className='modal-outer' centered show={show} onHide={handleClose}>
                <Modal.Body >
                    <span className='close' onClick={handleClose}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.265 19.7375C4.38604 19.8519 4.52389 19.9293 4.67855 19.9697C4.83321 20.0101 4.98787 20.0101 5.14253 19.9697C5.29719 19.9293 5.43168 19.8519 5.54599 19.7375L12.0014 13.277L18.4568 19.7375C18.5711 19.8519 18.7056 19.9293 18.8602 19.9697C19.0149 20.0101 19.1695 20.0101 19.3242 19.9697C19.4856 19.9361 19.6234 19.8587 19.7378 19.7375C19.8521 19.6231 19.926 19.4885 19.9597 19.3338C20 19.179 20 19.0242 19.9597 18.8694C19.926 18.7146 19.8521 18.58 19.7378 18.4656L13.2824 11.995L19.7378 5.53438C19.8521 5.41998 19.9294 5.28538 19.9697 5.1306C20.0101 4.97581 20.0101 4.82103 19.9697 4.66625C19.9294 4.51146 19.8521 4.37687 19.7378 4.26246C19.6167 4.14132 19.4789 4.06393 19.3242 4.03028C19.1695 3.98991 19.0149 3.98991 18.8602 4.03028C18.7056 4.06393 18.5711 4.14132 18.4568 4.26246L12.0014 10.723L5.54599 4.26246C5.43168 4.14132 5.29383 4.06393 5.13244 4.03028C4.97778 3.98991 4.82312 3.98991 4.66846 4.03028C4.5138 4.06393 4.37931 4.14132 4.265 4.26246C4.15069 4.37687 4.07336 4.51146 4.03301 4.66625C3.99939 4.82103 3.99939 4.97581 4.03301 5.1306C4.07336 5.28538 4.15069 5.41998 4.265 5.53438L10.7204 11.995L4.265 18.4656C4.15069 18.58 4.07336 18.7146 4.03301 18.8694C3.99266 19.0242 3.9893 19.179 4.02292 19.3338C4.06327 19.4885 4.14396 19.6231 4.265 19.7375Z" fill="black" />
                        </svg>
                    </span>
                    <div className='page-head'>
                        <div className='steps'>
                            <div className='step-item active'>
                                <i class="fa-regular fa-circle-check"></i>
                                <span>
                                    Import
                                </span>
                            </div>
                            <div className='step-item'>
                                <i class="fa-regular fa-circle-check"></i>
                                <span>
                                    Export
                                </span>
                            </div>
                            <div className='step-item'>
                                <i class="fa-regular fa-circle-check"></i>
                                <span>
                                    Import
                                </span>
                            </div>
                        </div>
                        <h1 className='heading'>
                            Import your project
                        </h1>
                    </div>
                    <div className='drag-project-card'>
                        <p>Drag archive with your project</p>
                        <button className='btn btn-light'>
                            Import Project
                        </button>
                    </div>
                    <p className='sub-heading'>Make sure you upload all documents correctly</p>
                    <div className='documents-verification'>
                        <div className='doc-point'>
                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.71094 21.9414C8.24609 21.9414 7.84049 21.7227 7.49414 21.2852L0.822266 12.8906C0.712891 12.7448 0.626302 12.6035 0.5625 12.4668C0.507812 12.321 0.480469 12.1751 0.480469 12.0293C0.480469 11.7103 0.585286 11.4505 0.794922 11.25C1.00456 11.0404 1.27344 10.9355 1.60156 10.9355C1.99349 10.9355 2.32617 11.127 2.59961 11.5098L8.65625 19.2891L20.4277 0.613281C20.5736 0.394531 20.7194 0.239583 20.8652 0.148438C21.0111 0.0572917 21.1979 0.0117188 21.4258 0.0117188C21.7448 0.0117188 22 0.111979 22.1914 0.3125C22.3919 0.513021 22.4922 0.772786 22.4922 1.0918C22.4922 1.2194 22.4694 1.35156 22.4238 1.48828C22.3783 1.625 22.3053 1.77995 22.2051 1.95312L9.85938 21.3125C9.57682 21.7318 9.19401 21.9414 8.71094 21.9414Z" fill="black" />
                            </svg>
                            <span>
                                3D Project
                            </span>
                        </div>
                        <div className='doc-point'>
                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.71094 21.9414C8.24609 21.9414 7.84049 21.7227 7.49414 21.2852L0.822266 12.8906C0.712891 12.7448 0.626302 12.6035 0.5625 12.4668C0.507812 12.321 0.480469 12.1751 0.480469 12.0293C0.480469 11.7103 0.585286 11.4505 0.794922 11.25C1.00456 11.0404 1.27344 10.9355 1.60156 10.9355C1.99349 10.9355 2.32617 11.127 2.59961 11.5098L8.65625 19.2891L20.4277 0.613281C20.5736 0.394531 20.7194 0.239583 20.8652 0.148438C21.0111 0.0572917 21.1979 0.0117188 21.4258 0.0117188C21.7448 0.0117188 22 0.111979 22.1914 0.3125C22.3919 0.513021 22.4922 0.772786 22.4922 1.0918C22.4922 1.2194 22.4694 1.35156 22.4238 1.48828C22.3783 1.625 22.3053 1.77995 22.2051 1.95312L9.85938 21.3125C9.57682 21.7318 9.19401 21.9414 8.71094 21.9414Z" fill="black" />
                            </svg>
                            <span>
                                Plant
                            </span>
                        </div>
                        <div className='doc-point'>
                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.71094 21.9414C8.24609 21.9414 7.84049 21.7227 7.49414 21.2852L0.822266 12.8906C0.712891 12.7448 0.626302 12.6035 0.5625 12.4668C0.507812 12.321 0.480469 12.1751 0.480469 12.0293C0.480469 11.7103 0.585286 11.4505 0.794922 11.25C1.00456 11.0404 1.27344 10.9355 1.60156 10.9355C1.99349 10.9355 2.32617 11.127 2.59961 11.5098L8.65625 19.2891L20.4277 0.613281C20.5736 0.394531 20.7194 0.239583 20.8652 0.148438C21.0111 0.0572917 21.1979 0.0117188 21.4258 0.0117188C21.7448 0.0117188 22 0.111979 22.1914 0.3125C22.3919 0.513021 22.4922 0.772786 22.4922 1.0918C22.4922 1.2194 22.4694 1.35156 22.4238 1.48828C22.3783 1.625 22.3053 1.77995 22.2051 1.95312L9.85938 21.3125C9.57682 21.7318 9.19401 21.9414 8.71094 21.9414Z" fill="black" />
                            </svg>
                            <span>
                                Specifications book
                            </span>
                        </div>
                        <div className='doc-point'>
                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.71094 21.9414C8.24609 21.9414 7.84049 21.7227 7.49414 21.2852L0.822266 12.8906C0.712891 12.7448 0.626302 12.6035 0.5625 12.4668C0.507812 12.321 0.480469 12.1751 0.480469 12.0293C0.480469 11.7103 0.585286 11.4505 0.794922 11.25C1.00456 11.0404 1.27344 10.9355 1.60156 10.9355C1.99349 10.9355 2.32617 11.127 2.59961 11.5098L8.65625 19.2891L20.4277 0.613281C20.5736 0.394531 20.7194 0.239583 20.8652 0.148438C21.0111 0.0572917 21.1979 0.0117188 21.4258 0.0117188C21.7448 0.0117188 22 0.111979 22.1914 0.3125C22.3919 0.513021 22.4922 0.772786 22.4922 1.0918C22.4922 1.2194 22.4694 1.35156 22.4238 1.48828C22.3783 1.625 22.3053 1.77995 22.2051 1.95312L9.85938 21.3125C9.57682 21.7318 9.19401 21.9414 8.71094 21.9414Z" fill="black" />
                            </svg>
                            <span>
                                Engineering plan
                            </span>
                        </div>
                        <div className='doc-point'>
                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.71094 21.9414C8.24609 21.9414 7.84049 21.7227 7.49414 21.2852L0.822266 12.8906C0.712891 12.7448 0.626302 12.6035 0.5625 12.4668C0.507812 12.321 0.480469 12.1751 0.480469 12.0293C0.480469 11.7103 0.585286 11.4505 0.794922 11.25C1.00456 11.0404 1.27344 10.9355 1.60156 10.9355C1.99349 10.9355 2.32617 11.127 2.59961 11.5098L8.65625 19.2891L20.4277 0.613281C20.5736 0.394531 20.7194 0.239583 20.8652 0.148438C21.0111 0.0572917 21.1979 0.0117188 21.4258 0.0117188C21.7448 0.0117188 22 0.111979 22.1914 0.3125C22.3919 0.513021 22.4922 0.772786 22.4922 1.0918C22.4922 1.2194 22.4694 1.35156 22.4238 1.48828C22.3783 1.625 22.3053 1.77995 22.2051 1.95312L9.85938 21.3125C9.57682 21.7318 9.19401 21.9414 8.71094 21.9414Z" fill="black" />
                            </svg>
                            <span>
                                Selected material price list
                            </span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );

}