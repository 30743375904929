import { Link, Carousel, Button, InputGroup, Accordion, FormControl, Row, Col, ProgressBar, Form, Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useState } from 'react';
import DragProject from '../components/DragProject';
import AnalyzingProject from '../components/AnalyzingProject';
import ImportProjectCancel from '../components/ImportProjectCancel';
import PremiumTemplate from '../components/PremiumTemplate';
import ShareQR from '../components/ShareQR';
import ChooseTemplate from '../components/ChooseTemplate';
import BuildingProject from '../components/BuildingProject';

AOS.init();
export default function Index() {

    return (
        <div className='components-body'>
            <DragProject />
            <AnalyzingProject />
            <ImportProjectCancel />
            <PremiumTemplate />
            <ShareQR />
            <ChooseTemplate />
            <BuildingProject />
        </div>
    );

}